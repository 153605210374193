<template>
	<!-- nav -->
	<nav class="nav">
		<div class="nav__wrap">
			<a href="#" class="nav__btn nav__btn--prev">
				<i class="icon icon icon-left">이전 페이지</i>
			</a>
			<p class="nav__item">
				<span class="nav__text"> 검색 </span>
			</p>
			<a href="#" class="nav__btn nav__btn--map">
				<i class="icon icon-map"></i>
			</a>
		</div>
	</nav>
	<!-- //nav-->
	<div id="container" class="container container--fit">
		<div id="sub" class="sub quicksearch">
			<!-- search-form -->
			<section class="search-form">
				<div class="box">
					<div class="search-form__wrap">
						<input type="text" class="ipt ipt--muted" value="역삼">
						<button type="submit" class="btn-search">
							<i class="icon icon-search"></i>
						</button>
					</div>
				</div>
			</section>
			<!-- [D] 활성화된 tab에 active 클래스 추가 -->
			<section class="listcartegory">
				<div class="box">
					<div class="tab tab--half">
						<a href="#">숙박</a>
						<a href="#" class="active">레저/티켓</a>
					</div>
				</div>
			</section>
			<!-- content -->
			<section class="content">
				<div class="box">
					<div class="menus">
						<div class="menus__wrap">
							<!-- [D] 활성화된 지역에 active 클래스 추가 -->
							<!-- [REST] 지역 분류 관리자 페이지에서 설정 -->
							<div class="menus__box">
								<div class="menus__text active">전체</div>
								<div class="menus__text">액티비티</div>
								<div class="menus__text">테마파크</div>
								<div class="menus__text">공연/전시</div>
								<div class="menus__text">워터파크/스파</div>
								<div class="menus__text">축제/행사</div>
								<div class="menus__text">교통</div>
							</div>
						</div>
					</div>
				</div>
				<div class="listbox">
                    <div class="box">
                        <div class="list__meta">
                            <div class="list__check">
                                <div class="checkbox">
                                    <label>
                                        <input type="checkbox" class="chk blind">
                                        <span class="checkbox__bg"></span>
                                        쿠폰할인 가능한 숙소
                                    </label>
                                </div>
                            </div>
                            <div class="list__option">
                                <button type="button">
                                    <i class="icon icon-align">
                                        정렬
                                    </i>
                                </button>
                            </div>
                        </div>
                    </div>
					<!-- [REST] 숙소 리스트 -->
					<ul class="list list--full">
						<li>
							<div class="item">
								<router-link to="/rooms/1" class="list__link">
									<!-- [D] 뱃지 있을 시 추가 -->
									<div class="card__badge badge badge--coupon">
										<span class="badge__text">
											1만원 할인쿠폰
										</span>
									</div>
									<div class="list__wrap">
										<div class="list__image">
											<img src="@/assets/images/temp-leisure01.jpg" alt="역삼 바운스 트램폴린">
										</div>
										<div class="list__data">
											<div class="list__box flex">
												<div class="list__slogan info-slogan">
													다이나믹 트램폴린 체험!
												</div>
												<div class="list__grad info-grad">
												<span class="list__icon info-icon">
													<i class="icon icon-star"></i>
												</span>
													<span class="list__gradnum info-gradnum">
													9.7
												</span>
													<span class="list__gradcounter info-gradcounter">
													2,400
												</span>
												</div>
											</div>
											<h3 class="list__title info-title">
												[서울] 역삼 바운스 트램폴린
											</h3>
										</div>
                                        <ul class="list__info">
                                            <li>
                                                <div class="list__info-desc">
                                                    당일사용가능 <br>
                                                    미사용 전액 환불
                                                </div>
                                                <div class="list__info-price info-price">
                                                    <div class="info-price__sale">
                                                        <strong>14%</strong>
                                                        <span>35,000</span>
                                                    </div>
                                                    <div class="info-price__current">
                                                        <strong>30,000</strong>원
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
									</div>
								</router-link>
								<ul class="item__menu item__menu--top">
									<li>
										<a href="#">
											<!-- [D] 픽 한 숙소일 경우 icon-pick--point2 클래스 -->
											<i class="icon icon-pick--point2">쿠폰</i>
										</a>
									</li>
								</ul>
							</div>
						</li>
                        <li>
							<div class="item">
								<router-link to="/rooms/1" class="list__link">
									<!-- [D] 뱃지 있을 시 추가 -->
									<div class="card__badge badge badge--coupon">
										<span class="badge__text">
											1만원 할인쿠폰
										</span>
									</div>
									<div class="list__wrap">
										<div class="list__image">
											<img src="@/assets/images/temp-leisure01.jpg" alt="역삼 바운스 트램폴린">
										</div>
										<div class="list__data">
											<div class="list__box flex">
												<div class="list__slogan info-slogan">
													다이나믹 트램폴린 체험!
												</div>
												<div class="list__grad info-grad">
												<span class="list__icon info-icon">
													<i class="icon icon-star"></i>
												</span>
													<span class="list__gradnum info-gradnum">
													9.7
												</span>
													<span class="list__gradcounter info-gradcounter">
													2,400
												</span>
												</div>
											</div>
											<h3 class="list__title info-title">
												[서울] 역삼 바운스 트램폴린
											</h3>
										</div>
                                        <ul class="list__info">
                                            <li>
                                                <div class="list__info-desc">
                                                    당일사용가능 <br>
                                                    미사용 전액 환불
                                                </div>
                                                <div class="list__info-price info-price">
                                                    <div class="info-price__sale">
                                                        <strong>14%</strong>
                                                        <span>35,000</span>
                                                    </div>
                                                    <div class="info-price__current">
                                                        <strong>30,000</strong>원
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
									</div>
								</router-link>
								<ul class="item__menu item__menu--top">
									<li>
										<a href="#">
											<i class="icon icon-pick">쿠폰</i>
										</a>
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</div>
                <!-- temp -->
                <div class="btns" style="display:none">
                    <button type="button" class="btn btn-submit">
                        버튼 영역
                    </button>
                </div>
			</section>
			<!-- //content -->
		</div>
	</div>
</template>